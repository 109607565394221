<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加配件类别</en-button>
    </template>

    <flex-box>
      <template #default="{ height }">
        <en-table :data="table.data" :height="height" :loading="table.loading" :method="table.get" pagination :paging="table.paging">
          <en-table-column label="生产配件名称" prop="goodsSpecification.goods">
            <template #default="{ row }: { row: EnocloudManufacturingDefinitions['ManufacturingMaterialDto'] }">
              <en-button type="primary" text @click="table.name.click(row)">{{ row.goodsSpecification?.goods?.name }}</en-button>
            </template>
          </en-table-column>
          <en-table-column label="生产配件编号" prop="goodsSpecification.goods.serialNo"></en-table-column>

          <en-table-column label="生产规格" prop="goodsSpecification.name"></en-table-column>
        </en-table>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer
    v-model="detail.visible"
    :title="detail.form.data.manufacturingMaterialGoods.id ? '编辑' : '添加' + '生产配件'"
    direction="btt"
    size="70%"
  >
    <en-form
      :model="detail.form.data"
      :rules="detail.form.rules"
      :loading="detail.form.loading"
      :ref="setRef('detailForm')"
      class="grid grid-cols-5 gap-6"
    >
      <en-form-item label="生产配件编号" prop="goodsSpecification">
        <select-maintain
          v-model="detail.form.data.goodsSpecification.goods"
          :ajax="{
            action: 'GET /enocloud/common/goods',
            params: (params, value) => {
              params.payload = { statusCode: 'A', name: value }
            }
          }"
          :props="{ label: 'serialNo', value: '' }"
          value-key="id"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="生产配件名称">
        <en-input v-model="detail.form.data.goodsSpecification.goods.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="生产数量" prop="count">
        <en-input v-model="detail.form.data.count"></en-input>
      </en-form-item>
      <en-form-item label="状态" prop="goodsSpecification">
        <select-maintain
          v-model="detail.form.data.status"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => {
              params.paths = ['MAFMTSTAT']
            }
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
    </en-form>

    <flex-box>
      <template #default="{ height }">
        <en-table :data="detail.form.data.manufacturingMaterialGoods" :height="height" :loading="detail.form.loading">
          <en-table-column label="操作" width="200">
            <template #default="{ row }: { row: EnocloudManufacturingDefinitions['ManufacturingMaterialDto'] }">
              <en-button @click="detail.form.inspectionPlanItems.deletes.click" class="cursor-pointer text-sm text-primary" text>删除</en-button>
            </template>
          </en-table-column>
          <en-table-column label="编码" prop="goodsSpecification.goods.serialNo"></en-table-column>
          <en-table-column label="名称" prop="goodsSpecification.goods.name"></en-table-column>
          <en-table-column label="配件类别" prop="goodsSpecification.goods.category.name"></en-table-column>
          <en-table-column label="数量">
            <template #default="{ row }: { row: EnocloudManufacturingDefinitions['ManufacturingMaterialDto'] }">
              <en-input-number v-model="row.count" :min="0" :step="1"></en-input-number>
            </template>
          </en-table-column>
          <en-table-column label="规格" prop="goodsSpecification.name"></en-table-column>
          <en-table-column label="基本单位换算">
            <template #default="{ row }: { row: EnocloudManufacturingDefinitions['ManufacturingMaterialDto'] }">
              {{ `${row.goodsSpecification?.weight}${row.goodsSpecification?.goods?.warehouseUnit}` }}
            </template>
          </en-table-column>
          <en-table-column label="品牌" prop="goodsSpecification.goods.brand"></en-table-column>
          <en-table-column label="产地" prop="goodsSpecification.goods.placeOfProduction"></en-table-column>
          <en-table-column label="备注" prop="comment"> </en-table-column>
          <!-- <en-table-column label="是否可用">
            <template #default="{ row }: { row: EnocloudManufacturingDefinitions['ManufacturingMaterialDto'] }">
              <en-checkbox v-model="row.enabled.value"></en-checkbox>
            </template>
          </en-table-column> -->
        </en-table>
      </template>
    </flex-box>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <en-button @click="detail.footer.edit.click" type="primary">添加配件</en-button>
      <button-ajax :method="detail.footer.confirm.click"> 确定 </button-ajax>
    </template>
  </en-drawer>
  <en-dialog v-model="edit.visible" style="width: 85%; height: 85%" title="添加配件">
    <flex-box>
      <template #default="{ height }">
        <table-dynamic
          code="GDSPKFD"
          :data="topup.data"
          :loading="topup.loading"
          :height="height - 55"
          pagination
          :paging="topup.paging"
          :method="topup.get"
        ></table-dynamic>
      </template>
    </flex-box>
    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>

      <button-ajax :method="detail.footer.confirm.click"> 确定 </button-ajax>
    </template>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      form: {
        is: 'form',
        data: {}
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/manufacturing/material',
            data: 'array',
            loading: true,
            pagination: true
          },
          delete: {
            action: 'DELETE /enocloud/manufacturing/material/:materialId',
            loading: true
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnocloudManufacturingDefinitions['ManufacturingMaterialDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            }
          },
          name: {
            click(row: EnocloudManufacturingDefinitions['ManufacturingMaterialDto']) {
              this.detail.form.init()
              this.detail.form.data.id = row.id
              this.detail.form.get()
              this.detail.visible = true
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              count: 1,
              goodsSpecification: {
                goods: {},
                name: '',
                category: { name: '' }
              },
              manufacturingMaterialGoods: [],
              comment: '',
              productGoods: {}
            },
            ajax: {
              get: {
                action: 'GET /enocloud/manufacturing/material/:materialId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/manufacturing/material',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/manufacturing/material',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: {
              name: [{ required: true, message: '请输入类别名称' }]
            },
            children: {
              inspectionPlanItems: {
                deletes: {
                  click(index: number) {
                    this.detail.form.data.manufacturingMaterialGoods.splice(index, 1)
                  }
                }
              }
            }
          },
          table: {
            ajax: {
              delete: {
                action: 'DELETE /enocloud/manufacturing/material/:materialId',
                loading: true
              }
            }
          },

          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            edit: {
              click() {
    
                this.detail.visible = false
                this.edit.visible = true
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      },
      edit: {
        visible: false
      },
      topup: {
        ajax: {
          get: {
            action: 'GET /enospray/common/goods',
            data: 'array',
            loading: true,
            pagination: true
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
